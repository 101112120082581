.brx-scrollable-base {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);

  .brx-scrollable-parent {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .brx-scrollable-target {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
  }
}
