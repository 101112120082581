import { Directive, ElementRef, Renderer2, AfterViewInit, inject, OnDestroy } from '@angular/core';
import { BrxScrollableWrapperComponent } from './brx-scrollable-wrapper.component';

/**
 * Директива, которая указывает, что элемент является "скролл-таргетом".
 * Автоматически навешивает .brx-scrollable-target на себя
 * и .brx-scrollable-parent на всех родителей до компонента-обёртки brx-scrollable-wrapper
 */
@Directive({
  selector: '[brxScrollableTarget]',
})
export class BrxScrollableTargetDirective implements AfterViewInit, OnDestroy {
  private readonly elRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  /**
   * Компонент-обёртка, если найден в инжекторе
   */
  private readonly scrollableWrapper = inject(BrxScrollableWrapperComponent, { optional: true });

  private resizeObserver: ResizeObserver | null = null;

  /**
   * Храним исходный (базовый) paddingRight в пикселях.
   * Например, если по CSS у вас стоит padding-right: 10px,
   * мы её запомним и будем прибавлять 12.
   */
  private originalPaddingRight = 0;

  ngAfterViewInit(): void {
    // Навешиваем класс .brx-scrollable-target на сам элемент
    this.renderer.addClass(this.elRef.nativeElement, 'brx-scrollable-target');

    // Считываем и парсим текущий padding-right, чтобы запомнить
    const styles = window.getComputedStyle(this.elRef.nativeElement as HTMLElement);
    this.originalPaddingRight = parseFloat(styles.paddingRight || '0px') || 0;

    // Если директива оказалась внутри brx-scrollable-wrapper,
    // используем метод процессинга у обёртки
    if (this.scrollableWrapper) {
      this.scrollableWrapper.processParentScrollables(this.elRef.nativeElement as HTMLElement);
    } else {
      // Если нет обёртки (случай, когда вы забыли обернуть, или не нужно),
      // тогда просто идём вверх по родителям до body/html
      let parent = this.elRef.nativeElement.parentElement;
      while (parent) {
        this.renderer.addClass(parent, 'brx-scrollable-parent');
        parent = parent.parentElement;
      }
    }

    // Сделаем первую проверку изменения размеров
    this.addPaddingWhenScrollIsVisible();
    // Включаем ResizeObserver, чтобы смотреть, когда меняются размеры
    this.resizeObserver = new ResizeObserver(() => {
      this.addPaddingWhenScrollIsVisible();
    });
    this.resizeObserver.observe(this.elRef.nativeElement as HTMLElement);
  }

  ngOnDestroy(): void {
    // Отключаем наблюдатель при уничтожении
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  private addPaddingWhenScrollIsVisible(): void {
    requestAnimationFrame(() => {
      const el = this.elRef.nativeElement;
      // Проверяем, появляется ли вертикальная прокрутка
      if (el.scrollHeight > el.clientHeight) {
        // Добавляем 10px к исходному padding-right
        const newPadding = this.originalPaddingRight + 5;
        this.renderer.setStyle(el, 'padding-right', `${newPadding}px`);
      } else {
        // Возвращаем исходный padding-right
        this.renderer.setStyle(el, 'padding-right', `${this.originalPaddingRight}px`);
      }
    });
  }
}
