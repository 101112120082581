import {
  Component,
  ElementRef,
  Renderer2,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  inject,
  HostBinding,
} from '@angular/core';

/**
 * Компонент-обёртка для контента с прокруткой.
 * Обычно используется для фиксации высоты с прокручиваемым контентом в модалках
 */
@Component({
  selector: 'brx-scrollable-wrapper',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./brx-scrollable-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BrxScrollableWrapperComponent {
  private readonly elRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  @HostBinding('class.brx-scrollable-base') baseClass = true;

  /**
   * Метод, который вызывается директивой brxScrollableTarget
   * для навешивания класса .brx-scrollable-parent на всех родителей
   * от target-элемента до нашего компонента-обёртки (не включая сам хост).
   */
  processParentScrollables(target: HTMLElement): void {
    let parent = target.parentElement;
    // Пока не дойдем до хоста
    while (parent && parent !== this.elRef.nativeElement) {
      this.renderer.addClass(parent, 'brx-scrollable-parent');
      parent = parent.parentElement;
    }
  }
}
